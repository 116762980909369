import React from 'react'
import './style.css'
import GamesHeader from './GamesHeader'
import image from "../Images/DiscordBotHelp(Updated).png"

function DiscordBotInfo() {
  return (
    <div>
        <GamesHeader />
    <body className='bodyInfo'>
    <div className="page2Info">
    <section className="containerInfo">
        <div className="card">
            <div className="card-image card6"></div>
            <h3 className="card-title">Discord Bot</h3>
            <p>A discord bot where you can play small mini games such as noughts and crosses and battleships</p>
        </div>
    </section>
    <div className="text">
    <p className="paragraphInfoWhite">In the begning of the Covid-19 pandamic, I made a discord bot (In python using discord.py) where you can play games against either a discord user or the bot, however the bot was highly ineffiecn</p> 
    <p className="paragraphInfoWhite">I updated the bot in the begning of 2022 making the code much more effecient and adding extra features such as mutliple game instances at once.</p>
    <p className="paragraphInfoWhite"><a href="https://discord.com/api/oauth2/authorize?client_id=792716092359442462&permissions=8&scope=bot%20applications.commands" />You can invite the bot here</p>
</div>
<div className="image">
<img src={image}/>
</div>
    </div>
</body>

</div>
  )
}

export default DiscordBotInfo