import './App.css';
import Header from './Components/Header/Header';
import About from './Components/About/About';
import Projects from './Components/Projects/Projects';
import Music from './Components/Music/Music';

import CubemaniaInfo from './Components/Projects/ProjectInfo/MoreInfo/CubemaniaInfo';

import { BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import DiscordBotInfo from './Components/Projects/ProjectInfo/MoreInfo/DiscordBotInfo';
import JavaIDEInfo from './Components/Projects/ProjectInfo/MoreInfo/JavaIDEInfo';
import MusicBotInfo from './Components/Projects/ProjectInfo/MoreInfo/MusicBotInfo';
import OneWordInfo from './Components/Projects/ProjectInfo/MoreInfo/OneWordInfo';
import PasswordManagerInfo from './Components/Projects/ProjectInfo/MoreInfo/PasswordManagerInfo';
import SolarSystemInfo from './Components/Projects/ProjectInfo/MoreInfo/SolarSystemInfo';
import SwitchJumpInfo from './Components/Projects/ProjectInfo/MoreInfo/SwitchJumpInfo';
import UniOrganiser from './Components/Projects/ProjectInfo/MoreInfo/UniOrganiserInfo';
import PlatfomerInfo from './Components/Projects/ProjectInfo/MoreInfo/PlatfomerInfo';

import PageNotFound from './PageNotFound';

function App() {
  return (
    <Router>
    <div className="App">
      <Switch>
        <Route exact path='/'>
          <Header />
          <About />
          <Projects />
          <Music />
        </Route>

        <Route exact path="/cubemania">
          <CubemaniaInfo />
        </Route>

        <Route exact path="/discordbot">
          <DiscordBotInfo />
        </Route>

        <Route exact path='/javaide'>
          <JavaIDEInfo />
        </Route>

        <Route exact path="/musicbot">
          <MusicBotInfo />
        </Route>

        <Route exact path="/oneword">
          <OneWordInfo />
        </Route>

        <Route exact path="/passwordmanager">
          <PasswordManagerInfo />
        </Route>

        <Route exact path='/solarsystem'>
          <SolarSystemInfo />
        </Route>

        <Route exact path='/switchjump'>
          <SwitchJumpInfo />
        </Route>

        <Route exact path='/uniorganiser'>
          <UniOrganiser />
        </Route>

        <Route exact path='/platformer'>
          <PlatfomerInfo />
        </Route>

        <Route path=''>
          <PageNotFound />
        </Route>
      </Switch>
    </div>
    </Router>
  );
}

export default App;
