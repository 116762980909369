import React from 'react'
import './about.css'
import AOS from 'aos';
import 'aos/dist/aos.css'
import {SiPython} from 'react-icons/si'
import {SiJava} from 'react-icons/si'

function About() {
  AOS.init({duration: 2000});
  return (
    <div className='aboutContainer'>
    <div className='containerFloat'>
      <div className='text'>
        <div data-aos="fade-right" className='Header'><h2>About</h2> </div>
        </div>
      <div data-aos="fade-left" data-aos-delay="1000" className='text'>
        <div className='Paragraph'>
          <p>I’m currently a Software Engineering undergraduate with a diploma in Performance; ARSM [Associate of the Royal Schools of Music]. I am currently studying at Swansea University, and I’ve completed my first year of studies. I love to code, and I’m always interested in learning anything new when it comes to programming. I code small projects for fun and to teach my self new concepts such as new languages. My main languages are Python and Java (Which I’ve been learning at uni), but I have used C#, JavaScript, HTML and a bit of VBScript in small one-off projects. I coded this website as a project after first year exams and also to have a place to store my other one-off programs. Front-end dev isn’t really my thing (or my favourite thing to program), but I thought I’d give it a go. </p>
          <br></br>
          <br></br>
          <div className='LanguageIcons'>
            <a className='SocialButton' href='https://github.com/ZonicoGaming' target="_blank"><SiPython size={60}/></a>
            <a className='SocialButton' href='https://github.com/ZonicoGaming' target="_blanks"><SiJava size={60}/></a>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default About