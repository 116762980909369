import React from 'react'
import GamesHeader from './GamesHeader'
import './style.css'
import image from "../Images/uniOrganiserLogin.png"

function UniOrganiser() {
  return (
    <div>
        <GamesHeader />
        <body className='bodyInfo'>
    <div className="page2">
    <section className="containerInfo">
        <div className="card">
            <div className="card-image card10"></div>
            <h3 className="card-title">Univeristy Organiser</h3>
            <p>A program that will help to organise assignments and your timetable</p>
        </div>
    </section>
    <div className="text">
    <p className="paragraphInfoWhite">This was created as a big (ish) project that I would hope that it wasn't too hard or too easy while also would take time to do.</p>
    <p className="paragraphInfoWhite">It's still very much WIP and the GUI doesn't look that fancy (Graphics aren't my forte :)).</p>
    <p className="paragraphInfoWhite">Currently you can add and remove assignments as well as add files linked to that assignment</p>
</div>
<div className="image">
<img src={image} />
</div>
</div>
</body>
    </div>
  )
}

export default UniOrganiser