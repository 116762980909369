import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom'
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import image from "./Images/Java IDE.png"; 

function JavaIDE() {
  return (
    <div>
      <Card sx={{ maxWidth: 500 }} style={{boxShadow : '5px 20px 60px rgba(0, 0, 0, 0.35)'}}>
        <CardActionArea component={RouterLink} to="/javaide">
      <CardMedia
        component="img"
        height="300"
        image={image}
        alt="Java Editor"
      />
      <CardContent sx={{ minHeight : 140}} style={{backgroundColor : "#494949"}}>
        <Typography gutterBottom variant="h5" component="div" color="white" textAlign="left" fontFamily="'Roboto', sans-serif" fontWeight="bold">
        Java Editor    
         </Typography>
        <Typography variant="body2" color="white" textAlign="left" fontFamily="'Poppins', sans-serif">
        A program that will open .java files, compile and run them
        </Typography>
      </CardContent>
      </CardActionArea>
    </Card>
    </div>
  )
}

export default JavaIDE