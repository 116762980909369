import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom'
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import image from "./Images/headphones.png"; 

function MusicBot() {
  return (
    <div>
      <Card sx={{ maxWidth: 500 }} style={{boxShadow : '5px 20px 60px rgba(0, 0, 0, 0.35)'}}>
        <CardActionArea component={RouterLink} to="/musicbot">
      <CardMedia
        style={{tintColor: "#EEEEEE"}}
        component="img"
        height="300"
        image={image}
        alt="Music Bot"
      />
      <CardContent sx={{ minHeight : 140}} style={{backgroundColor : "#494949"}}>
        <Typography gutterBottom variant="h5" component="div" color="white" textAlign="Roboto" fontFamily="'roboto', sans-serif" fontWeight="bold">
        Music Bot 
         </Typography>
        <Typography variant="body2" color="white" textAlign="left" fontFamily="'Poppins', sans-serif">
        A music bot on discord that will play songs in voice channels
        </Typography>
      </CardContent>
      </CardActionArea>
    </Card>
    </div>
  )
}

export default MusicBot