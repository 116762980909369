import React from 'react'
import './projects.css'
import AOS from 'aos';
import 'aos/dist/aos.css'

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import UniOrganiser from './ProjectInfo/UniOrganiser';
import SolarSystem from './ProjectInfo/SolarSystem';
import Cubemania from './ProjectInfo/Cubmania';
import PythonPlaformer from './ProjectInfo/PythonPlatformer';
import SwitchJump from './ProjectInfo/SwitchJump';
import JavaIDE from './ProjectInfo/JavaIDE';
import PasswordManager from './ProjectInfo/PasswordManager';
import DiscordBot from './ProjectInfo/DiscordBot';
import OneWord from './ProjectInfo/OneWordBot';
import MusicBot from './ProjectInfo/MusicDiscordBot';
import Pathfinding from './ProjectInfo/Pathfinding'

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 3, // optional, default to 1.
        partialVisibilityGutter: 40
      },
      tablet: {
        breakpoint: { max: 1024, min: 600 },
        items: 2,
        slidesToSlide: 2, // optional, default to 1.
        partialVisibilityGutter: 40
      },
      mobile: {
        breakpoint: { max: 600, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
        partialVisibilityGutter: 40
      }
    
};

const CustomRightArrow = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType }
    } = rest;
    // onMove means if dragging or swiping in progress.
    return <button onClick={() => onClick()} />;
  };

function Projects() {
    AOS.init({duration: 1500});
  return (
    <div className='projectsContainer'>
    <div data-aos="fade-up" className='projectsHeader'><h2>Projects</h2> </div>
    <Carousel
    className='cards'
    swipeable={false}
    draggable={false}
    partialVisible={true}
    responsive={responsive}
    ssr={true} // means to render carousel on server-side.
    infinite={true}
    /*
    autoPlay={true}
    autoPlaySpeed={1000}
    keyBoardControl={true}
    customTransition="all .5"
    transitionDuration={500}
    */
    itemClass="carousel-item-padding-40-px"
    containerClass="carousel-container"
    removeArrowOnDeviceType={["tablet", "mobile"]}
    //deviceType={this.props.deviceType}
>
        <Pathfinding />
        <UniOrganiser />
        <SolarSystem />
        <Cubemania />
        <PythonPlaformer />
        <SwitchJump />
        <JavaIDE />
        <PasswordManager />
        <DiscordBot />
        <OneWord />
        <MusicBot />
    </Carousel>
    </div>
  )
}

export default Projects