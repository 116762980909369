import React from 'react'
import './style.css'
import GamesHeader from './GamesHeader'

function PasswordManagerInfo() {
  return (
    <div>
        <GamesHeader />
        <body className='bodyInfo'>
    <div className="page2">
    <section className="containerInfo">
        <div className="card">
            <div className="card-image card5"></div>
            <h3 className="card-title">Password Manager</h3>
            <p>A program that will create and store passwords locally in an encrypted txt file</p>
        </div>
    </section>
    <div className="text">
    <p className="paragraphInfoWhite">I did this due to a codewars challenge I enjoyed coding, called <a href="https://www.codewars.com/kata/58c5577d61aefcf3ff000081">Rail Fence Cipher</a>.</p>
    <p className="paragraphInfoWhite">And so I decicded to create a Password manager (in python), which is where you'd need to enter a password to log in (which was stored as a hash), and the program would randomly generate passwords and store them encrypted in a .txt file, the program would also encyrpt the .txt to make sure the no one could understand the data within the file if they had access.</p>
    <p className="paragraphInfoWhite">This actually taught me about how passwords are stored in a hash as well as how to encyrpt and decrpyt data using a key. I was lazy with the keys, with the keys being stored at the end of the .txt file in reality you wouldn't want to implement this as a hacker would be able to decrpyt the data easily.</p>
    <p className="paragraphInfoWhite">Another thing I learnt was about byte strings or b'' in python which need to be encoded() and decoded(), this was also techinaclly another layer of security in the text file as the values in the .txt one decrpyted had to be decoded from bytes to a string</p>
    </div>
    </div>
</body>
    </div>
  )
}

export default PasswordManagerInfo