import React from 'react'
import './style.css'
import GamesHeader from './GamesHeader'

function CubemaniaInfo() {
  return (
    <div>
    <GamesHeader />
    <body className='bodyInfo'>
        <div className="page2">
        <section className="containerInfo">
            <div className="card">
                <div className="card-image card1"></div>
                <h3 className="card-title">Cubemania</h3>
                <p>Cubemania is a simple level game, in which you control a square and have to avoid the blocks infront of you. This was created to teach my self C#</p>
            </div>
        </section>
        <div className="text">
        <p className="paragraphInfoWhite">The game is pretty simple which has set levels.</p>
        <p className="paragraphInfoWhite">It's purpose wasn't to create a fun game, but rather to get me used to how C# worked and unity.</p>
        <p className="paragraphInfoWhite">To create it I created it by following <a href="https://www.youtube.com/channel/UCYbK_tjZ2OrIZFBvU6CCMiA">Brackeys</a> who has a great <a href="https://www.youtube.com/watch?v=j48LtUkZRjU&list=PLPV2KyIb3jR5QFsefuO2RlAgWEz6EvVi6&ab_channel=Brackeys">beginner tutorial.</a></p>
        </div>
    </div>
    </body>
    </div>
  )
}

export default CubemaniaInfo