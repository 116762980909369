import React from 'react'
import GamesHeader from './GamesHeader'
import './style.css'

function SwitchJumpInfo() {
  return (
    <div>
        <GamesHeader />
        <body className='bodyInfo'>
    <div className="page2">
    <section className="containerInfo">
        <div className="card">
            <div className="card-image card3"></div>
            <h3 className="card-title">Switch Jump</h3>
            <p>A Platformer where every x numver of seconds the direction of the player changes</p>
        </div>
    </section>
    <div className="text">
    <p className="paragraphInfoWhite">This game is simular to the <a href="/platformer">python platfomer</a>, but I made it more complex such that the player will change direction ever number of x seconds.</p>
    <p className="paragraphInfoWhite">I created this to implement what I learnt in my <a href="/cubemania">last C# project</a>, and to see if I could code in C# without following a tutorial. This was created using Unity's game engine and it taught me alot about how classes work in C# and how to implement them.</p>
    <p className="paragraphInfoWhite">Bellow you can see the video of me creating the game:</p>
    </div>
    <div className="videoInfo">
        <iframe width="894" height="503" src="https://www.youtube.com/embed/8pPYUsI1T2I" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    </div>
</body>
    </div>
  )
}

export default SwitchJumpInfo