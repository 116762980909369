import React from 'react'
import GamesHeader from './GamesHeader'
import image from '../Images/Java IDE.png'

function JavaIDEInfo() {
  return (
    <div>
        <GamesHeader />
        <body className='bodyInfo'>
            <div className="page2">
                 <section className="containerInfo">
                      <div className="card">
                        <div className="card-image card4"></div>
                        <h3 className="card-title">Java IDE</h3>
                        <p>A program that will open .java files, compile and run them</p>
                      </div>
                 </section>
                <div className="text">
                    <p className="paragraphInfoWhite">This was created purley for fun as I was bored however it taught me valuble lessons.</p>
                    <p className="paragraphInfoWhite">This isn't really an IDE but what you can do is open, edit, save, compile and run (.className) java files, however you can only enter one input (as I don't have the time to implement mutlipul).</p>
                    <p className="paragraphInfoWhite">This was created in python and it made me recap the Tkinter module, however it did teach me about subprossess in python. This is as the way it compeles it simply creates a new subprosses and runs/compiles the code in command prompt</p>
                </div>
                <div className="image">
                <img src={image} />
            </div>
            </div>
        </body>
    </div>
  )
}

export default JavaIDEInfo