import React from 'react'
import './style.css'
import GamesHeader from './GamesHeader'

function PlatfomerInfo() {
  return (
    <div>
        <GamesHeader />
        <body className='bodyInfo'>
    <div className="page2">
    <section className="containerInfo">
        <div className="card">
            <div className="card-image card2"></div>
            <h3 className="card-title">Python Platformer</h3>
            <p>A simple 2D Platformer game created to teach myself pygame and to see what I could code in 1 hour </p>
        </div>
    </section>
    <div className="text">
    <p className="paragraphInfoWhite">The game is pretty simple and has infinate randomly generated platforms.</p>
    <p className="paragraphInfoWhite">I set myself a challange to see what I could code in 1 hour in python using pygame and this is the result.</p>
    <p className="paragraphInfoWhite">I later developed the game and recreated it in <a href="/switchjump">C# using unity.</a></p>
    <p className="paragraphInfoWhite">Bellow you can see the video of me creating the game:</p>
    </div>
    <div className="videoInfo">
    <iframe width="894" height="503" src="https://www.youtube.com/embed/-AxUZeUrxvA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    </div>
</body>
    </div>
  )
}

export default PlatfomerInfo