import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom'
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import imageS from "./Images/Earth.png"; 

function SolarSystem() {
  return (
    <div>
      <Card sx={{ maxWidth: 500 }} style={{boxShadow : '5px 20px 60px rgba(0, 0, 0, 0.35)'}}>
        <CardActionArea component={RouterLink} to="/solarsystem">
      <CardMedia
        component="img"
        height="300"
        image={imageS}
        alt="Solar System"
      />
      <CardContent sx={{ minHeight : 140}} style={{backgroundColor : "#494949"}}>
        <Typography gutterBottom variant="h5" component="div" color="white" textAlign="left" fontFamily="'Roboto', sans-serif" fontWeight="bold">
        Solar System Simulation
        </Typography>
        <Typography variant="body2" color="white" textAlign="left" fontFamily="'Poppins', sans-serif">
        A 2D Simulation of the solar System where you can add/remove/edit planets
        </Typography>
      </CardContent>
      </CardActionArea>
    </Card>
    </div>
  )
}

export default SolarSystem