import React from 'react'
import GamesHeader from './GamesHeader'
import './style.css'
import imageOne from "../Images/OneWordtHelp.png"
import imageTwo from "../Images/OneWordStory.png"

function OneWordInfo() {
  return (
    <div>
        <GamesHeader />

        <body className='bodyInfo'>
    <div className="page2">
    <section className="containerInfo">
        <div className="card">
            <div className="card-image card7"></div>
            <h3 className="card-title">One Word Disocrd bot</h3>
            <p>A second discord bot where every day will realse a word and who ever tells the best story/picture releated to that word wins</p>
        </div>
    </section>
    <div className="text">
    <p className="paragraphInfoWhite">This bot was created as a sort of improvment of <a href="/discordbot">my last discord bot (before I updated it)</a>, with it having much more effecient code.</p>
    <p className="paragraphInfoWhite">What this bot does is every day it'll generate two random words, one word you have to create a story with and the other you have to draw a picture. Other discord users must then vote who they think was the best for each word. They would then get points depending if they came 1st, 2nd or 3rd.</p>
    <p className="paragraphInfoWhite">I created this to remind myself SQL databases, so each of the players scores were stored in a database as well as the discord servers' info such as where the channel the bot will send the words.</p>
    <p className="paragraphInfoWhite">I don't plan on making it public since I don't want to pay to keep a bot running 24/7</p>
</div>
<div className="image">
<img src={imageOne} />
</div>
<p> </p>
<div className="image">
    <img src={imageTwo} />
</div>
</div>
</body>
    </div>
  )
}

export default OneWordInfo