import React from 'react'
import './HeaderInfo.css'

function GamesHeader() {
  return (
    <div className='InfoHeader'>
        <h1 className='logo'><a href='/'>D.Banks</a></h1>
    </div>
  )
}

export default GamesHeader