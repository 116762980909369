import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {BsYoutube} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'
import {BsDiscord} from 'react-icons/bs'
import {SiCodewars} from 'react-icons/si'
import AOS from 'aos';
import 'aos/dist/aos.css'

function HeaderSocials() {
  AOS.init({duration: '500'})
  return (
    <div className='headerSocials'>
        <a className='SocialButton' data-aos="fade-up" data-aos-delay="500" href='https://www.linkedin.com/in/daniel-banks-8b064b226/' target="_blank"><BsLinkedin size={60}/></a>
        <a className='SocialButton' data-aos="fade-up" data-aos-delay="750" href='https://www.youtube.com/channel/UCqKGCkg6r8g-5U7bai1He-g' target="_blanks"><BsYoutube size={60}/></a>
        <a className='SocialButton' data-aos="fade-up" data-aos-delay="1000" href='https://github.com/dbanks93' target='_blank'><FaGithub size={60}/></a>
        <a className='SocialButton' data-aos="fade-up" data-aos-delay="1250" href='https://discord.com/invite/AZJ2yAJmma'><BsDiscord size={60}/></a>
        <a className='SocialButton' data-aos="fade-up" data-aos-delay="1500" href='https://www.codewars.com/users/Dan0901'><SiCodewars size={60}/></a>
    </div>
  )
}

export default HeaderSocials