import React from 'react'
import {GiSadCrab} from 'react-icons/gi'
function PageNotFound() {
  return (
    <div>
        <h1><GiSadCrab size={100} /></h1>
        <h2>Error 404</h2>
        <p>Page not found <a href='/'> Go back to home</a></p>
    </div>
  )
}

export default PageNotFound