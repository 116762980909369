import React from 'react'
import './music.css'
import image from '../../assests/Pattern-Transparent-Background.png'

function Music() {
  return (
    <div className='MusicSection' style={{ backgroundImage:`url(${image})`,backgroundRepeat:"no-repeat",backgroundSize:"contain"}}>
        <div className='MusicHeader' data-aos="flip-down">
            <h2 className='HeaderMusic'>Music</h2>
        </div>
        <div className='MusicCotent'>
            <div className='containerFloat'>
                <div className='text'>
                    <div data-aos="zoom-in" data-aos-delay="1000" className='video'>
                        <p class="paragraphWhite"> Here you can see an expample of a band I was in playing in the National Youth Brass Band Championship 2018 </p>
                        <iframe width="894" height="503" src="https://www.youtube.com/embed/LN-qUzTAE7I" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
                <div data-aos="zoom-in-left"  className='textM'>
                    <div className='ParagraphMusic'>
                        <p>In my spare time I play the Trumpet and have played in multiple bands in and outside of Birmingham as well as local theatre productions in Solihull, I even took part and came third in the National Youth Brass Band Championship (2018) sadly the 2020 National Youth Brass Band Championship was cancelled due to covid. I have played in multiple places such as Cambridge University, University of Birmingham (UoB), Birmingham Symphony Hall, Royal Birmingham Conservatoire, Warwick Arts Centre and even the Cavern club and Abby Road Studios and many many more. However, my favourite performances are duets with an organ (especially at Christmas).</p>
                    </div>
                </div>
            </div>    
            <div classname='MusicWaffle' data-aos="fade-up" data-aos-delay="1500">
                <p className='ParagraphMusicFull'>Music has always been a big part of my life, with playing the piano from the age of 6 and the Trumpet from the age of 7. I have recently become the Orchestra Manager for Swansea Uni Musicians Society (2022-2023). Very occasionally I compose for brass ensembles/quartets, so far I have only done a brass ensemble arrangement of John Williams Liberty Fanfare</p>    
            </div> 
        </div>
    </div>
  )
}

export default Music