import React from 'react'
import GamesHeader from './GamesHeader'
import image from '../Images/SolarSystemedid.png'
import './style.css'
//import projectPDF from '../Images/Solar_System_Simulation_Documentation'
import projectPDF from '../Images/Solar_System_Simulation_Documentation.pdf'

function SolarSystemInfo() {
  return (
    <div>
        <GamesHeader />
        <body className='bodyInfo'>
    <div className="page2">
    <section className="containerInfo">
        <div className="card">
            <div className="card-image card9"></div>
            <h3 className="card-title">Solar System Simulation</h3>
            <p>A 2D Simulation of the solar System where you can add/remove/edit planets</p>
        </div>
    </section>
    <div className="text">
    <p className="paragraphInfoWhite">This was created for my A-Level computer science coursework.</p>
    <p className="paragraphInfoWhite">By far this was the biggest project I had undertaken and a massive learning curve, however it taught me how to implent stuff I'd learnd as part of my exam such as classes, functions and bring it all togther. Sadly due to covid (as the schools shut) I didn't have much time to work on it so it doesn't look as good as I planned and still contains bugs such as zooming in and out sometimes doesn't work, but the main functions do still work.</p>
    <p className="paragraphInfoWhite"><a href={projectPDF}> here is the documentation if you wish to check it out or want to create something simular yourself.</a></p>
</div>
<p> </p>
<div className="image">
    <img src={image} />
</div>
    </div>
</body>
    </div>
  )
}

export default SolarSystemInfo