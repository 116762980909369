import React from 'react'
import './style.css'
import GamesHeader from './GamesHeader'
import imageOne from "../Images/MusicBotHelp.png"
import imageTwo from "../Images/MusicBot.png"

function MusicBotInfo() {
  return (
    <div>
        <GamesHeader />
        <body className='bodyInfo'>
            <div className="page2">
                <section className="containerInfo">
                    <div className="card">
                         <div className="card-image card8"></div>
                         <h3 className="card-title">Music Discord bot</h3>
                          <p>A music bot on discord that will play songs in voice channels</p>
                     </div>
                  </section>
                  <div className="text">
                    <p className="paragraphInfoWhite">Since alot of the music discord bots got taken down due to copyright reasons from youtube, I deceiced to create my own for my and my friends servers.</p>
                    <p className="paragraphInfoWhite">This like my other <a href="/">discord bots</a> this bot was coded in python and I had to use the youtube.dl module to download songs.</p>
                    <p className="paragraphInfoWhite"><em>(I am not releasing this bot as I do not want a cease and desist letter from youtube)</em></p>
                  </div>
                <div className="image">
                   <img src={imageOne} />
                </div>
                <p> </p>
            </div>
            <div className="image">
                <img src={imageTwo} />
            </div>
        </body>
    </div>
  )
}

export default MusicBotInfo