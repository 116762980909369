import React from 'react'
import './header.css'
import ProfileAvitar from '../../assests/TrumpetPhoto.png'
import HeaderSocials from './HeaderSocials'

function Header() {
  return (
    <header> 
      <div className='container header__container'>
      <div className='wrapper'>
        <h1 className='typingAnimation'>Welcome to Daniel Banks' Website</h1>
        </div>
        <h5>This is just a website I made for fun, to showcase my programs and to teach myself react</h5>
        <HeaderSocials />
        <div className='profileAvitar'>
          <img src={ProfileAvitar} alt='Me' />
        </div>
      </div>
    </header>
  )
}

export default Header