import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom'
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import image from './Images/Pathfinding.png';

function Pathfinding() {
  return (
    <div>
      <Card sx={{ maxWidth: 500 }} style={{boxShadow : '5px 20px 60px rgba(0, 0, 0, 0.35)'}}>
        <CardActionArea href="https://github.com/DBanks93/pathfinding"> 
      <CardMedia
        component="img"
        height="300"
        image={image}
        alt="Uni Project"
      />
      <CardContent sx={{ minHeight : 140}} style={{backgroundColor : "#494949"}}>
        <Typography gutterBottom variant="h5" component="div" color="white" textAlign="left" fontFamily="'Roboto', sans-serif" fontWeight="bold">
        Pathfinding
        </Typography>
        <Typography variant="body2" color="white" textAlign="left" fontFamily="'Poppins', sans-serif">
        A visualization of some of the pathfinding algorithms
        </Typography>
      </CardContent>

      </CardActionArea>
    </Card>
    </div>
  )
}

export default Pathfinding